import {
    CLEAR_ADD_CATALOG,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_CATALOG_CATALOG_DATA,
    UPDATE_CATALOG_FINAL_STEP_HAS_ERROR,
    UPDATE_CATALOG_FINAL_STEP_LOADING,
    UPDATE_CATALOG_FINAL_STEP_MESSAGE,
    UPDATE_CATALOG_ID,
    UPDATE_CATALOG_TEAM_DATA,
    UPDATE_STEP1_SOURCE_TYPE,
    UPDATE_STEP1_TEAM_NAME,
    UPDATE_STEP2_AUTH_PASSWORD,
    UPDATE_STEP2_AUTH_USERNAME,
    UPDATE_STEP2_CAN_GO_TO_NEXT_STEP,
    UPDATE_STEP2_CSVTSV_DELIMITER,
    UPDATE_STEP2_EXCEL_WORKSHEET,
    UPDATE_STEP2_GOOGLESHEETS_WORKSHEET,
    UPDATE_STEP2_IS_PASSWORD_PROTECTED,
    UPDATE_STEP2_NAME,
    UPDATE_STEP2_URL,
    UPDATE_URL_VALIDATION,
    UPDATE_STEP2_WOOCOMMERCE_VALIDATION,
    UPDATE_STEP2_CONSUMER_KEY,
    UPDATE_STEP2_CONSUMER_SECRET,
    UPDATE_STEP2_SHOP_URL,
    UPDATE_STEP3_COUNTRY,
    UPDATE_STEP3_LANGUAGE,
    UPDATE_STEP3_TIMEZONE,
    UPDATE_STEP4_SCHEDULE_LIST,
    UPDATE_STEP5_AVAILABILITY_COUNT_CHANGE,
    UPDATE_STEP5_PRICE_COUNT_CHANGE,
    UPDATE_STEP5_PRODUCT_COUNT_CHANGE,
    UPDATE_STEP5_PRODUCT_DONT_IMPORT,
    HAS_SHOPIFY_URL_ERROR,
    UPDATE_SHOPIFY_AUTH_LOGIN,
    CHANGE_SIDE_TAB,
} from '../../shared/constants/ActionTypes';
import {CatalogSourceType} from '../../shared/constants/AppEnums';
const INIT_STATE = {
    // Step 1
    step1_sourceType: '',
    step1_teamName: {name: '', id: ''},
    canGoFromStep1: false,
    // Step 2
    step2_name: null,
    step2_URL: '',
    step2_consumerKey: null,
    step2_consumerSecret: null,
    step2_shopUrl: null,
    step2_isPasswordProtected: false,
    step2_authUsername: null,
    step2_authPassword: null,
    step2_excel_Worksheet: '',
    step2_googleSheet_Worksheet: '',
    step2_csvTsv_delimiter: '',
    hasShopifyUrlError: false,
    canGoFromStep2: false,
    // Step 3
    step3_country: null,
    step3_language: null,
    step3_timezone: null,
    canGoFromStep3: false,
    // Step 4
    step4_scheduleList: null,
    canGoFromStep4: false,
    // Step 5
    step5_productDontImport: null,

    step5_productCountChange: null,
    step5_availabilityCountChange: null,
    step5_priceCountChange: null,
    canFinishTheCatalogAdd: false,
    // Final Step - Info
    responseMessage: '',
    responseStatusHasError: false,
    responseLoading: false,
    catalogID: '',
    //Edit
    catalogData: null,
    team: null,
    sidetab: {selected: 0},
};

const catalogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            sessionStorage.clear();
            return INIT_STATE;
        }
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case CLEAR_ADD_CATALOG: {
            sessionStorage.setItem('CR_WIZARD_CATALOG_ACTIVE_STEP', '0');
            return INIT_STATE;
        }
        case UPDATE_STEP1_SOURCE_TYPE: {
            // No need to clean Step2 Form if same option is selected
            if (state.step1_sourceType !== action.payload) {
                return {
                    ...state,
                    step1_sourceType: action.payload,
                    canGoFromStep1: !!state.step1_teamName?.name,
                    step2_URL: '',
                    isURLValid: true,
                    isWooCommerceValid: true,
                    step2_isPasswordProtected: false,
                    step2_authUsername: null,
                    step2_authPassword: null,
                    step2_excel_Worksheet: null,
                    step2_googleSheet_Worksheet: null,
                    step2_csvTsv_delimiter: '',
                    canGoFromStep2: false,
                    step2_shopUrl: null,
                };
            } else {
                return {
                    ...state,
                    step1_sourceType: action.payload,
                    canGoFromStep1: true,
                };
            }
        }
        case UPDATE_STEP1_TEAM_NAME: {
            return {
                ...state,
                step1_teamName: action.payload,
                canGoFromStep1: !!state.step1_sourceType,
            };
        }
        case UPDATE_STEP2_NAME: {
            return {
                ...state,
                step2_name: action.payload,
            };
        }
        case UPDATE_STEP2_URL: {
            return {
                ...state,
                step2_URL: action.payload,
            };
        }
        case UPDATE_URL_VALIDATION: {
            return {
                ...state,
                isURLValid: action.payload,
            };
        }
        case UPDATE_STEP2_WOOCOMMERCE_VALIDATION: {
            return {
                ...state,
                isWooCommerceValid: action.payload,
            };
        }
        case UPDATE_STEP2_CONSUMER_KEY: {
            return {
                ...state,
                step2_consumerKey: action.payload,
            };
        }
        case UPDATE_STEP2_CONSUMER_SECRET: {
            return {
                ...state,
                step2_consumerSecret: action.payload,
            };
        }
        case UPDATE_STEP2_SHOP_URL: {
            return {
                ...state,
                step2_shopUrl: action.payload,
            };
        }

        case HAS_SHOPIFY_URL_ERROR: {
            return {
                ...state,
                hasShopifyUrlError: action.payload,
            };
        }
        case UPDATE_SHOPIFY_AUTH_LOGIN: {
            return {
                ...state,
                shopifyAuthLogin: action.payload,
            };
        }

        case UPDATE_STEP2_IS_PASSWORD_PROTECTED: {
            if (action.payload) {
                return {
                    ...state,
                    step2_isPasswordProtected: action.payload,
                };
            } else {
                return {
                    ...state,
                    step2_isPasswordProtected: action.payload,
                    step2_authUsername: null,
                    step2_authPassword: null,
                };
            }
        }
        case UPDATE_STEP2_AUTH_USERNAME: {
            return {
                ...state,
                step2_authUsername: action.payload,
            };
        }
        case UPDATE_STEP2_AUTH_PASSWORD: {
            return {
                ...state,
                step2_authPassword: action.payload,
            };
        }
        case UPDATE_STEP2_EXCEL_WORKSHEET: {
            return {
                ...state,
                step2_excel_Worksheet: action.payload,
            };
        }
        case UPDATE_STEP2_GOOGLESHEETS_WORKSHEET: {
            return {
                ...state,
                step2_googleSheet_Worksheet: action.payload,
            };
        }
        case UPDATE_STEP2_CSVTSV_DELIMITER: {
            return {
                ...state,
                step2_csvTsv_delimiter: action.payload,
            };
        }
        case UPDATE_STEP2_CAN_GO_TO_NEXT_STEP: {
            const URL_AuthComplete = state.step2_isPasswordProtected
                ? !!(
                      state.step2_name &&
                      state.step2_URL &&
                      state.step2_authUsername &&
                      state.step2_authPassword
                  )
                : !!(state.step2_name && state.step2_URL);
            const CONSUMER_COMPLETE = !!(
                state.step2_consumerKey && state.step2_consumerSecret
            );
            const SHOP_URL_COMPLETE = !!(
                state.step2_shopUrl && state.step2_name
            );
            switch (state.step1_sourceType) {
                case CatalogSourceType.XML: {
                    return {
                        ...state,
                        canGoFromStep2: !!URL_AuthComplete && state.isURLValid,
                    };
                }
                case CatalogSourceType.EXCEL: {
                    return {
                        ...state,
                        canGoFromStep2: !!(
                            URL_AuthComplete && state.step2_excel_Worksheet
                        ),
                    };
                }
                case CatalogSourceType.GOOGLE_SHEETS: {
                    return {
                        ...state,
                        canGoFromStep2: !!(
                            URL_AuthComplete &&
                            state.step2_googleSheet_Worksheet
                        ),
                    };
                }
                case CatalogSourceType.CSV_TSV: {
                    return {
                        ...state,
                        canGoFromStep2: !!(
                            URL_AuthComplete &&
                            state.step2_csvTsv_delimiter &&
                            state.isURLValid
                        ),
                    };
                }
                case CatalogSourceType.WooCommerce: {
                    return {
                        ...state,
                        canGoFromStep2: !!(
                            URL_AuthComplete &&
                            CONSUMER_COMPLETE &&
                            state.isWooCommerceValid
                        ),
                    };
                }
                case CatalogSourceType.Shopify: {
                    return {
                        ...state,
                        canGoFromStep2: !!SHOP_URL_COMPLETE,
                    };
                }
                default:
                    return state;
            }
        }
        case UPDATE_STEP3_COUNTRY: {
            return {
                ...state,
                step3_country: action.payload,
                canGoFromStep3: !!(
                    action.payload &&
                    state.step3_language &&
                    state.step3_timezone
                ),
            };
        }
        case UPDATE_STEP3_LANGUAGE: {
            return {
                ...state,
                step3_language: action.payload,
                canGoFromStep3: !!(
                    action.payload &&
                    state.step3_country &&
                    state.step3_timezone
                ),
            };
        }
        case UPDATE_STEP3_TIMEZONE: {
            return {
                ...state,
                step3_timezone: action.payload,
                canGoFromStep3: !!(
                    action.payload &&
                    state.step3_country &&
                    state.step3_language
                ),
            };
        }
        case UPDATE_STEP4_SCHEDULE_LIST: {
            return {
                ...state,
                step4_scheduleList: action.payload,
            };
        }
        case UPDATE_STEP5_PRODUCT_DONT_IMPORT: {
            return {
                ...state,
                step5_productDontImport: action.payload,
                canFinishTheCatalogAdd: !!(
                    action.payload &&
                    state.step5_productCountChange &&
                    state.step5_availabilityCountChange &&
                    state.step5_priceCountChange
                ),
            };
        }

        case UPDATE_STEP5_PRODUCT_COUNT_CHANGE: {
            return {
                ...state,
                step5_productCountChange: action.payload,
                canFinishTheCatalogAdd: !!(
                    action.payload &&
                    state.step5_productDontImport &&
                    state.step5_availabilityCountChange &&
                    state.step5_priceCountChange
                ),
            };
        }
        case UPDATE_STEP5_AVAILABILITY_COUNT_CHANGE: {
            return {
                ...state,
                step5_availabilityCountChange: action.payload,
                canFinishTheCatalogAdd: !!(
                    action.payload &&
                    state.step5_productDontImport &&
                    state.step5_productCountChange &&
                    state.step5_priceCountChange
                ),
            };
        }
        case UPDATE_STEP5_PRICE_COUNT_CHANGE: {
            return {
                ...state,
                step5_priceCountChange: action.payload,
                canFinishTheCatalogAdd: !!(
                    action.payload &&
                    state.step5_productDontImport &&
                    state.step5_productCountChange &&
                    state.step5_availabilityCountChange
                ),
            };
        }
        case UPDATE_CATALOG_FINAL_STEP_MESSAGE: {
            return {
                ...state,
                responseMessage: action.payload,
            };
        }
        case UPDATE_CATALOG_FINAL_STEP_HAS_ERROR: {
            return {
                ...state,
                responseStatusHasError: action.payload,
            };
        }
        case UPDATE_CATALOG_FINAL_STEP_LOADING: {
            return {...state, responseLoading: action.payload};
        }
        case UPDATE_CATALOG_ID: {
            return {...state, catalogID: action.payload};
        }
        case UPDATE_CATALOG_CATALOG_DATA: {
            return {...state, catalogData: action.payload};
        }
        case UPDATE_CATALOG_TEAM_DATA: {
            return {...state, team: action.payload};
        }
        case CHANGE_SIDE_TAB:
            return {
                ...state,
                sidetab: {
                    selected: action.payload.newValue,
                },
            };
        default:
            return state;
    }
};
export default catalogReducer;
