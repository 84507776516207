// ## API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

// ## APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';
export const SET_CHANNEL_PERCENTAGE = 'set_channel_percentage';

// ## AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SET_REFRESH_TOKEN = 'set_refresh_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';
export const UPDATE_GOOGLE_AUTH_DRIVE = 'update_google_auth_drive';
export const UPDATE_GOOGLE_AUTH_ANALYTICS = 'update_google_auth_analytics';
export const UPDATE_GOOGLE_AUTH_LOGIN = 'update_google_auth_login';
export const UPDATE_FACEBOOK_AUTH_LOGIN = 'update_facebook_auth_login';
export const UPDATE_GOOGLE_AUTH_SIGNUP = 'update_google_auth_signup';
export const UPDATE_SHOPIFY_AUTH_LOGIN = 'update_shopify_auth_login';
export const UPDATE_SHOPIFY_AUTH_REGISTER = 'update_shopify_auth_register';

// ## ProjectsAndTeams
export const CHANGE_TEAM_VIEW = 'change_team_view';
export const SET_TEAM_LIST = 'set_team_list';
export const SET_SELECTED_TEAM = 'set_selected_team';
export const SET_PEOPLE_LIST = 'set_people_list';
export const SET_PROJECT_LIST = 'set_project_list';
export const SET_AUTH_TEAM_LIST = 'set_auth_team_list';
export const SET_AUTH_TEAM_LIST_LOADING = 'set_auth_team_list_loading';

// ## ADD CATALOG
export const CLEAR_ADD_CATALOG = 'clear_add_catalog';
// STEP1
export const UPDATE_STEP1_SOURCE_TYPE = 'update_step1_source_type';
export const UPDATE_STEP1_TEAM_NAME = 'update_step1_team_name';
export const CHANGE_SIDE_TAB = 'change_side_tab';
export const CHANGE_SIDEBAR_PAGES = 'change_sidebar_pages';
export const CHANCE_FEED_MANAGEMENT = 'change_feed_management';

// STEP2
export const UPDATE_STEP2_NAME = 'update_step2_name';
export const UPDATE_STEP2_URL = 'update_step2_url';
export const UPDATE_STEP2_CONSUMER_KEY = 'update_step2_consumer_key';
export const UPDATE_STEP2_CONSUMER_SECRET = 'update_step2_consumer_secret';
export const UPDATE_STEP2_SHOP_URL = 'update_step2_shop_url';
/* export const UPDATE_STEP2_ACCESS_TOKEN = 'update_step2_access_token';
 */ export const HAS_SHOPIFY_URL_ERROR = 'has_shopify_url_error';
export const UPDATE_STEP2_WOOCOMMERCE_VALIDATION =
    'update_step2_woocommerce_validation';
export const UPDATE_STEP2_IS_PASSWORD_PROTECTED =
    'update_step2_is_password_protected';
export const UPDATE_URL_VALIDATION = 'update_url_validation';
export const UPDATE_STEP2_AUTH_USERNAME = 'update_step2_auth_username';
export const UPDATE_STEP2_AUTH_PASSWORD = 'update_step2_auth_password';
export const UPDATE_STEP2_XML_PRODUCT_NODE = 'update_step2_xml_product_node';
export const UPDATE_STEP2_EXCEL_WORKSHEET = 'update_step2_excel_worksheet';
export const UPDATE_STEP2_GOOGLESHEETS_WORKSHEET =
    'update_step2_googlesheets_worksheet';
export const UPDATE_GET_PUBLIC_SHEETS = 'update_get_public_sheets';
export const UPDATE_ADDITIONAL_PUBLIC_SHEETS =
    'update_additional_public_sheets';
export const UPDATE_STEP2_CSVTSV_DELIMITER = 'update_step2_csvtsv_delimiter';
export const UPDATE_STEP2_CAN_GO_TO_NEXT_STEP =
    'update_step2_can_go_to_next_step';

// STEP3
export const UPDATE_STEP3_COUNTRY = 'update_step3_country';
export const UPDATE_STEP3_LANGUAGE = 'update_step3_language';
export const UPDATE_STEP3_TIMEZONE = 'update_step3_timezone';

// STEP4
export const UPDATE_STEP4_SCHEDULE_LIST = 'update_step4_schedule_list';

// STEP5
export const UPDATE_STEP5_PRODUCT_DONT_IMPORT =
    'update_step5_product_dont_import';

export const UPDATE_STEP5_PRODUCT_COUNT_CHANGE =
    'update_step5_product_count_change';
export const UPDATE_STEP5_AVAILABILITY_COUNT_CHANGE =
    'update_step5_availability_count_change';
export const UPDATE_STEP5_PRICE_COUNT_CHANGE =
    'update_step5_price_count_change';
// FINAL-STEP
export const UPDATE_CATALOG_FINAL_STEP_MESSAGE =
    'update_catalog_final_step_message';
export const UPDATE_CATALOG_FINAL_STEP_HAS_ERROR =
    'update_catalog_final_step_has_error';
export const UPDATE_CATALOG_FINAL_STEP_LOADING =
    'update_catalog_final_step_loading';
export const UPDATE_CATALOG_ID = 'update_catalog_id';

// ## AUTH
export const UPDATE_AUTH_LOADING = 'update_auth_loading';
export const UPDATE_AUTH_MESSAGE = 'update_auth_message';
export const UPDATE_AUTH_HAS_ERROR = 'update_auth_has_error';
export const UPDATE_GOOGLE_MERCHANT_IDS = 'update_gmc_ids';

// ## EDIT CATALOG
export const UPDATE_CATALOG_CATALOG_DATA = 'update_catalog_catalog_data';
export const UPDATE_CATALOG_TEAM_DATA = 'update_catalog_team_data';

// ## DATA SOURCE
export const UPDATE_DATA_SOURCE_CATALOG_NAME =
    'update_data_source_catalog_name';
export const UPDATE_DATA_SOURCE_INFO = 'update_data_source_info';
export const UPDATE_EXPORT_LIST = 'update_export_list';
export const UPDATE_IMPORT_LIST = 'update_import_list';
export const UPDATE_TEMPLATES_LIST = 'update_templates_list';
export const UPDATE_MAPPING_INFO = 'update_mapping_info';
export const UPDATE_PREVIEW_DATA = 'update_preview_data';
export const UPDATE_GOOGLE_ANALYTICS_FIELDS = 'update_google_analytics_fields';
export const UPDATE_PRIMARY_FEED_FINAL_STEP_MESSAGE =
    'update_primary_feed_final_step_message';
export const UPDATE_PRIMARY_FEED_FINAL_STEP_HAS_ERROR =
    'update_primary_feed_final_step_has_error';
export const UPDATE_PRIMARY_FEED_FINAL_STEP_LOADING =
    'update_primary_feed_final_step_loading';
export const UPDATE_DATA_SOURCE_CHANNEL_DATA =
    'update_data_source_channel_data';
export const CLEAR_DATA_SOURCE = 'clear_data_source';

// ## SUPPORT
export const UPDATE_ANALYTICS_ACCOUNTS = 'update_analytics_accounts';
export const UPDATE_ANALYTICS_ACCOUNTS_LOADING =
    'update_analytics_accounts_loading';

export const UPDATE_ANALYTICS_PROPERTIES = 'update_analytics_properties';
export const UPDATE_ANALYTICS_PROPERTIES_LOADING =
    'update_analytics_properties_loading';
export const UPDATE_ANALYTICS_VIEWS = 'update_analytics_views';
export const UPDATE_ANALYTICS_VIEWS_LOADING = 'update_analytics_views_loading';
export const UPDATE_EXCEL_SHEET_LIST = 'update_excel_sheet_list';
export const UPDATE_GOOGLE_SHEET_LIST = 'update_google_sheet_list';
export const UPDATE_SHOPIFY_LIST = 'update_shopify_list';
export const UPDATE_SUPPORT_LOADING = 'update_support_loading';
export const UPDATE_SUPPORT_ERROR = 'update_support_error';
export const UPDATE_SUPPORT_MESSAGE = 'update_support_message';
export const UPDATE_ALL_ANALYTICS_DATA = 'update_all_analytics_data';
export const CLEAR_SUPPORT = 'clear_support';

// ## ADDITIONAL DATA SOURCE
export const UPDATE_ADDITIONAL_DATA_SOURCE_TYPE =
    'update_additional_data_source_type';
export const UPDATE_ADDITIONAL_DATA_STEP_INFO =
    'update_additional_data_step_info';
export const UPDATE_ADDITIONAL_DATA_SOURCE_ANALYTICS_ACCOUNT =
    'update_additional_data_source_analytics_account';
export const UPDATE_ADDITIONAL_DATA_SOURCE_ANALYTICS_PROPERTY_ =
    'update_additional_data_source_analytics_property';
export const UPDATE_ADDITIONAL_DATA_SOURCE_ANALYTICS_VIEW =
    'update_additional_data_source_analytics_view';
export const UPDATE_ADDITIONAL_DATA_SOURCE_NAME =
    'update_additional_data_source_name';
export const UPDATE_ADDITIONAL_DATA_SOURCE_URL =
    'update_additional_data_source_url';
export const UPDATE_ADDITIONAL_DATA_SOURCE_FIELD_VALUE =
    'update_additional_data_source_field_value';
export const UPDATE_ADDITIONAL_DATA_SOURCE_IS_PASSWORD_PROTECTED =
    'update_additional_data_source_is_password_protected';
export const UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_USERNAME =
    'update_additional_data_source_auth_username';
export const UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_PASSWORD =
    'update_additional_data_source_auth_password';
export const UPDATE_ADDITIONAL_DATA_SOURCE_FIRST_JOIN =
    'update_additional_data_source_first_join';
export const UPDATE_ADDITIONAL_DATA_SOURCE_SECOND_JOIN =
    'update_additional_data_source_second_join';
export const UPDATE_ADDITIONAL_DATA_SOURCE_THIRD_JOIN =
    'update_additional_data_source_third_join';
export const UPDATE_ADDITIONAL_DATA_SOURCE_FOURTH_JOIN =
    'update_additional_data_source_fourth_join';
export const UPDATE_ADDITIONAL_DATA_SOURCE_MAPPING =
    'update_additional_data_source_mapping';
export const UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS =
    'update_additional_data_source_import_fields';
export const UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_LOADING = `update_additional_data_source_import_fields_loading`;
export const UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_ERROR = `update_additional_data_source_import_fields_error`;
export const UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_DATA =
    'update_additional_data_source_edit_import_data';
export const UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_LOADING =
    'update_additional_data_source_edit_import_loading';
export const UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_ERROR =
    'update_additional_data_source_edit_import_error';
export const UPDATE_ADDITIONAL_DATA_SOURCE_CLEAR =
    'update_additional_data_source_clear';
export const UPDATE_ADDITIONAL_DATA_SOURCE_VIEW_MODE =
    'update_additional_data_source_view_mode';

// RULES
export const ADD_RULE = 'RULE_ADDED';
export const UPDATE_RULE = 'RULE_UPDATED';
export const UPDATE_ALL_RULES = 'ALL_RULES_UPDATED';
export const DELETE_RULE = 'RULE_DELETED';
export const GET_FIELDS = 'GET_FIELDS';
export const RECEIVE_RULES = 'RECEIVE_RULES';
export const GET_ID_AND_TYPE = 'GET_ID_AND_TYPE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const INCLUDE_OR_EXCLUDE = 'INCLUDE_OR_EXCLUDE';
export const RULE_PREVIEW_CHECK_BOX = 'RULE_PREVIEW_CHECK_BOX';
export const SET_SELECTED_INDEX = 'SET_SELECTED_RULE_INDEX';
