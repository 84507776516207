import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import catalogReducer from './Catalog';
import ProjectsAndTeamsReducer from './ProjectsAndTeams';
import rulesReducer from './Rules';
import dataSourceReducer from './DataSource';
import supportReducer from './Support';
import additionalDataSourceReducer from './AdditionalDataSource';
import {themeReducer} from 'redux/reducers/ThemeReducer';

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        auth: Auth,
        rules: rulesReducer,
        common: Common,
        catalog: catalogReducer,
        projectsAndTeams: ProjectsAndTeamsReducer,
        dataSource: dataSourceReducer,
        support: supportReducer,
        additionalDataSource: additionalDataSourceReducer,
        appTheme: themeReducer,
    });

export default reducers;
