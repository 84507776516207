import routes from 'modules';
import {ThemeMode} from '../../shared/constants/AppEnums';
import defaultConfig from '../../@crema/utility/DefaultConfig';
import {ThemeSetting} from '../actions/Theme';

export const initialState = {
    theme: defaultConfig.theme,
    footer: defaultConfig.footer,
    footerType: defaultConfig.footerType,
    themeMode: defaultConfig.themeMode,
    headerMode: defaultConfig.headerMode,
    themeStyle: defaultConfig.themeStyle,
    layoutType: defaultConfig.layoutType,
    isRTL: defaultConfig.theme.direction === 'rtl',
    locale: defaultConfig.locale,
    navStyle: defaultConfig.navStyle,
    rtAnim: defaultConfig.rtAnim,
    primary: defaultConfig.theme.palette.primary.main,
    sidebarColor: defaultConfig.theme.palette.sidebar.bgColor,
    secondary: defaultConfig.theme.palette.secondary.main,
    routes: routes,
    rtlLocale: defaultConfig.rtlLocale,
};

export function themeReducer(state = initialState, action) {
    switch (action.type) {
        case ThemeSetting.UPDATE_THEME_MODE: {
            let theme = state.theme;
            if (action.payload === ThemeMode.DARK) {
                theme.palette.type = ThemeMode.DARK;
                theme.palette.background = {
                    paper: '#313541',
                    default: '#393D4B',
                };
                theme.palette.text = {
                    primary: 'rgba(255, 255, 255, 0.87)',
                    secondary: 'rgba(255, 255, 255, 0.67)',
                    disabled: 'rgba(255, 255, 255, 0.38)',
                    hint: 'rgba(255, 255, 255, 0.38)',
                };
            } else {
                theme.palette.type = ThemeMode.LIGHT;
                theme.palette.background = {
                    paper: '#FFFFFF',
                    default: '#f3f4f6',
                };
                theme.palette.text = {
                    primary: 'rgba(0, 0, 0, 0.87)',
                    secondary: 'rgba(0, 0, 0, 0.67)',
                    disabled: 'rgba(0, 0, 0, 0.38)',
                    hint: 'rgba(0, 0, 0, 0.38)',
                };
            }
            return {
                ...state,
                theme,
                themeMode: action.payload,
            };
        }
        default:
            return state;
    }
}
