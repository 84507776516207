import React from 'react';
import {IMPERSONATE_PAGE} from '../../shared/constants/AppRoutes';

export const impersonatePageConfigs = [
    {
        auth: ['admin'],
        routes: [
            {
                path: IMPERSONATE_PAGE,
                component: React.lazy(() => import('.')),
            },
        ],
    },
];
