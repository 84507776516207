import React from 'react';
import {PROJECTS_AND_TEAMS_PATH} from '../../shared/constants/AppRoutes';

export const projectsAndTeamsPageConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: PROJECTS_AND_TEAMS_PATH,
                component: React.lazy(() => import('.')),
            },
        ],
    },
];
