import {
    SET_INITIAL_PATH,
    TOGGLE_NAV_COLLAPSED,
    SET_CHANNEL_PERCENTAGE,
} from '../../shared/constants/ActionTypes';

export const toggleNavCollapsed = () => {
    return (dispatch) => dispatch({type: TOGGLE_NAV_COLLAPSED});
};

export const setInitialPath = (initialPath) => {
    return (dispatch) =>
        dispatch({type: SET_INITIAL_PATH, payload: initialPath});
};

export const setChannelPercentage = (percentage_to_notify) => {
    return (dispatch) =>
        dispatch({type: SET_CHANNEL_PERCENTAGE, payload: percentage_to_notify});
};
