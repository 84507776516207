import React from 'react';
import {
    ERROR_PAGE_404_PATH,
    FORGET_PASSWORD_PATH,
    LOGIN_PATH,
    RESET_PASSWORD_PATH,
    SIGNUP_PATH,
    CONFIRM_SIGNUP_PATH,
    ACCOUNT_CONFIRMED,
} from '../../shared/constants/AppRoutes';

export const authRouteConfig = [
    {
        routes: [
            {
                path: LOGIN_PATH,
                component: React.lazy(() => import('./SignIn/index')),
            },
        ],
    },
    {
        routes: [
            {
                path: SIGNUP_PATH,
                component: React.lazy(() => import('./SignUp/index')),
            },
        ],
    },
    {
        routes: [
            {
                path: FORGET_PASSWORD_PATH,
                component: React.lazy(() => import('./ForgetPassword')),
            },
        ],
    },
    {
        routes: [
            {
                path: RESET_PASSWORD_PATH,
                component: React.lazy(() => import('./ResetPassword')),
            },
        ],
    },
    {
        routes: [
            {
                path: [ACCOUNT_CONFIRMED, CONFIRM_SIGNUP_PATH],
                component: React.lazy(() =>
                    import('./SignUp/confirm-signup-or-account'),
                ),
            },
        ],
    },
    {
        routes: [
            {
                path: ERROR_PAGE_404_PATH,
                component: React.lazy(() =>
                    import('../errorPages/Error404/index'),
                ),
            },
        ],
    },
];
