import {createUseStyles} from 'react-jss';
import {getColorAndBackground} from './Gravatar/constant';
import {makeStyles} from '@material-ui/core';
import {ThemeMode} from '../../shared/constants/AppEnums';

export const GravatarStyles = createUseStyles({
    parent: ({emailMd5, size}) => ({
        ...getColorAndBackground(emailMd5),
        position: 'relative',
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.15)',
        transition: 'all .2s ease-in',
        '&:hover': ({size}) => ({
            width: size + 4,
            height: size + 4,
        }),
    }),
    swatch: ({initials, size}) => ({
        // scale the text size depending on avatar size and
        // number of initials
        fontSize: size / (1.4 * Math.max([...initials].length, 2)),
        position: 'absolute',
        fontFamily: 'sans-serif',
        userSelect: 'none',
    }),
    img: ({size}) => ({
        position: 'absolute',
        width: size,
        height: size,
        top: 0,
        left: 0,
        borderRadius: '50%',
    }),
});

export const HeaderUserStyles = makeStyles((theme) => {
    return {
        crHeaderUser: {
            backgroundColor: (props) =>
                props.header ? 'transparent' : 'rgba(0,0,0,.08)',
            paddingTop: 9,
            paddingBottom: 9,
            minHeight: 56,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                paddingTop: 10,
                paddingBottom: 10,
                minHeight: 70,
            },
        },
        userInfo: {
            width: (props) => (!props.header ? 'calc(100% - 75px)' : '100%'),
        },
        userName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            maxWidth: 150,
            [theme.breakpoints.up('xl')]: {},
            color: (props) =>
                props.themeMode === ThemeMode.DARK || !props.header
                    ? 'white'
                    : '#313541',
        },
        pointer: {
            cursor: 'pointer',
            marginTop: 21,
        },
        menuItem: {
            padding: `8px 16px`,
        },
        menuItemIcon: {
            minWidth: '40px',
        },
        menuItemLabel: {
            marginRight: '12px',
        },
    };
});

export const ImpersonateWarningStyles = makeStyles((theme) => ({
    impersonateWarningBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        backgroundColor: 'rgba(233,0,0,0.7)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: 70,
        maxHeight: 70,
        zIndex: 1,
    },
}));

export const ModalWithSiteRenderStyles = makeStyles((theme) => ({
    iframeInModal: {
        width: '100%',
        height: '75vh',
    },
    modalButton: {
        width: 176,
        height: '100%',
    },
}));
