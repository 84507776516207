import React from 'react';
import {Link} from 'react-router-dom';
import {PROJECTS_AND_TEAMS_PATH} from '../../constants/AppRoutes';
import CardMedia from '@material-ui/core/CardMedia';

const AppLogo = () => {
    return (
        <React.Fragment>
            <Link to={PROJECTS_AND_TEAMS_PATH}>
                <CardMedia
                    title='crwizard'
                    image='favicon.png'
                    style={{
                        minWidth: 77,
                        maxWidth: 77,
                        minHeight: 45,
                        maxHeight: 45,
                    }}
                />
            </Link>
        </React.Fragment>
    );
};

export default AppLogo;
