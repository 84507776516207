import React from 'react';
import {
    EDIT_CHANNEL_MAPPING_PAGE,
    EDIT_CHANNEL_PREVIEW_PAGE,
    EDIT_CHANNEL_RULES_PAGE,
} from '../../../shared/constants/AppRoutes';

export const editChannelConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_CHANNEL_MAPPING_PAGE,
                component: React.lazy(() =>
                    import('./editChannelMapping/MappingChannel'),
                ),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_CHANNEL_RULES_PAGE,
                component: React.lazy(() => import('./editChannelRules')),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_CHANNEL_PREVIEW_PAGE,
                component: React.lazy(() => import('./ChannelPreview')),
            },
        ],
    },
];
