import axios from 'axios';

export const baseURL =
    localStorage.getItem('CRWIZARD_API_URL') || 'https://api.crwizard.com';
localStorage.setItem('CRWIZARD_API_URL', baseURL);

const jwtAxios = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

// When token timeout shorten use interceptor with refresh token
// jwtAxios.interceptors.response.use(
//     (res) => res,
//     (err) => {
//         if (
//             err?.response?.data?.messages?.message ===
//             'Token is invalid or expired'
//         ) {
//             store.dispatch(onJWTAuthSignOut());
//         }
//         return Promise.reject(err);
//     },
// );

export const setImpersonateToken = (impersonateAuthToken) => {
    if (impersonateAuthToken) {
        jwtAxios.defaults.headers.common['Authorization'] =
            'Bearer ' + impersonateAuthToken;
    }
};

export const setAuthToken = (authToken) => {
    if (authToken) {
        jwtAxios.defaults.headers.common['Authorization'] =
            'Bearer ' + authToken;

        localStorage.setItem('CR_WIZARD_TOKEN_AUTH', authToken);
    } else {
        delete jwtAxios.defaults.headers.common['Authorization'];
        localStorage.removeItem('CR_WIZARD_TOKEN_AUTH');
    }
};

export const setRefreshToken = (refreshToken) => {
    if (refreshToken) {
        localStorage.setItem('CR_WIZARD_TOKEN_REFRESH', refreshToken);
    } else {
        localStorage.removeItem('CR_WIZARD_TOKEN_REFRESH');
    }
};

export default jwtAxios;
