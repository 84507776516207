import React from 'react';

import {
    ERROR_PAGE_404_PATH,
    ERROR_PAGE_500_PATH,
} from '../../shared/constants/AppRoutes';

export const errorPagesConfigs = [
    {
        routes: [
            {
                path: ERROR_PAGE_404_PATH,
                component: React.lazy(() => import('./Error404')),
            },
        ],
    },
    {
        routes: [
            {
                path: ERROR_PAGE_500_PATH,
                component: React.lazy(() => import('./Error500')),
            },
        ],
    },
];
