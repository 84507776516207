import {
    CLEAR_DATA_SOURCE,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_DATA_SOURCE_CATALOG_NAME,
    UPDATE_DATA_SOURCE_CHANNEL_DATA,
    UPDATE_DATA_SOURCE_INFO,
    UPDATE_EXPORT_LIST,
    UPDATE_GOOGLE_ANALYTICS_FIELDS,
    UPDATE_IMPORT_LIST,
    UPDATE_MAPPING_INFO,
    UPDATE_PREVIEW_DATA,
    UPDATE_PRIMARY_FEED_FINAL_STEP_HAS_ERROR,
    UPDATE_PRIMARY_FEED_FINAL_STEP_LOADING,
    UPDATE_PRIMARY_FEED_FINAL_STEP_MESSAGE,
    UPDATE_TEMPLATES_LIST,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
    dataSourceInfo: null,
    catalogName: null,
    exportList: null,
    importList: null,
    templatesList: null,
    mappingInfo: {},
    previewData: null,
    responseMessage: '',
    responseStatusHasError: false,
    responseLoading: false,
    channelData: null,
    googleAnalyticsFields: [],
};

const dataSourceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case UPDATE_DATA_SOURCE_INFO: {
            return {
                ...state,
                dataSourceInfo: action.payload,
            };
        }
        case '@@router/LOCATION_CHANGE':
            return INIT_STATE;
        case UPDATE_DATA_SOURCE_CATALOG_NAME: {
            return {
                ...state,
                catalogName: action.payload,
            };
        }
        case UPDATE_EXPORT_LIST: {
            return {
                ...state,
                exportList: action.payload,
                responseLoading: false,
            };
        }
        case UPDATE_IMPORT_LIST: {
            return {
                ...state,
                importList: action.payload,
                responseLoading: false,
            };
        }
        case UPDATE_TEMPLATES_LIST: {
            return {
                ...state,
                templatesList: action.payload,
                responseLoading: false,
            };
        }
        case UPDATE_MAPPING_INFO: {
            return {
                ...state,
                mappingInfo: action.payload,
            };
        }
        case UPDATE_PREVIEW_DATA: {
            return {
                ...state,
                previewData: action.payload,
            };
        }
        case UPDATE_PRIMARY_FEED_FINAL_STEP_MESSAGE: {
            return {
                ...state,
                responseMessage: action.payload,
            };
        }
        case UPDATE_PRIMARY_FEED_FINAL_STEP_HAS_ERROR: {
            return {
                ...state,
                responseStatusHasError: action.payload,
            };
        }
        case UPDATE_PRIMARY_FEED_FINAL_STEP_LOADING: {
            return {...state, responseLoading: action.payload};
        }
        case UPDATE_DATA_SOURCE_CHANNEL_DATA: {
            return {...state, channelData: action.payload};
        }
        case UPDATE_GOOGLE_ANALYTICS_FIELDS: {
            return {...state, googleAnalyticsFields: action.payload};
        }
        case CLEAR_DATA_SOURCE: {
            return INIT_STATE;
        }
        default:
            return state;
    }
};

export default dataSourceReducer;
