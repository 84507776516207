import React from 'react';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {loadJWTUser} from '../../../redux/actions';
import {useHistory} from 'react-router-dom';
import {ImpersonateWarningStyles} from '../styles';
import Typography from '@material-ui/core/Typography';
import {setAuthToken} from '../../../@crema/services/jwt-api';

const ImpersonateWarning = (props) => {
    const classes = ImpersonateWarningStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, authToken} = useSelector(({auth}) => auth);

    const handleLogoutImpersonate = () => {
        const originalToken =
            localStorage.getItem('CR_WIZARD_TOKEN_AUTH') || authToken;
        setAuthToken(originalToken);
        dispatch(loadJWTUser(originalToken));
        history.push('/');
    };

    return user?.isImpersonate ? (
        <div className={classes.impersonateWarningBar}>
            <Typography variant='subtitle2'>IMPERSONATE MODE</Typography>
            <div>
                <Button
                    onClick={handleLogoutImpersonate}
                    color='primary'
                    variant='contained'
                    size='small'>
                    Logout from {user?.username}
                </Button>
            </div>
        </div>
    ) : null;
};

export default ImpersonateWarning;
