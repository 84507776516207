import {makeStyles} from '@material-ui/core';
import {alpha} from '@material-ui/core/styles';
import {Fonts} from 'shared/constants/AppEnums';

export const AppLayoutStyles = makeStyles(() => ({
    appAuth: {
        flex: 1,
        display: 'flex',
        position: 'relative',
        height: '100vh',
        backgroundColor: '#f3f4f6',
        backgroundSize: 'cover',

        '& .scrollbar-container': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        '& .main-content-view': {
            padding: 20,
        },
        '& .footer': {
            marginRight: 0,
            marginLeft: 0,
        },
    },
}));

const AutLayoutStyles = makeStyles((theme) => {
    return {
        // HEADER STYLES
        appToolbar: {
            paddingLeft: 20,
            paddingRight: 20,
            minHeight: 56,
            [theme.breakpoints.up('sm')]: {
                minHeight: 60,
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: 30,
                paddingRight: 30,
            },
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuIcon: {
            width: 35,
            height: 35,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.overrides.MuiCard.root.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
        sectionDesktop: {
            minHeight: 56,
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                minHeight: 70,
            },
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBar: {
            width: '100%',
            boxShadow: '4px 3px 4px 0px rgba(0,0,0,0.12)',
        },
        menuItemRoot: {
            padding: 0,
        },
        pointer: {
            cursor: 'pointer',
        },
        logoRoot: {
            verticalAlign: 'middle',
            display: 'inline-block',
            height: 30,
        },
        menuIconRoot: {
            width: '2.5rem',
            height: '2.5rem',
        },
        // CONTENT STYLES
        appMain: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            backgroundColor: theme.palette.background.default,
            paddingTop: 56,
            [theme.breakpoints.up('sm')]: {
                paddingTop: 70,
            },
        },
        mainContent: {
            flex: 1,
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                marginLeft: '4rem',
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '5rem',
            },
        },
        mainContainer: {
            width: '100%',
            paddingBottom: 10,
            [theme.breakpoints.up('lg')]: {
                width: 'calc(100vw - 4rem)',
            },
            [theme.breakpoints.up('xl')]: {
                width: 'calc(100vw - 5rem)',
            },
            '& > .scrollbar-container': {
                padding: '20px 20px 0',
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.up('md')]: {
                    padding: '30px 32px 0',
                },
            },
        },
        mainContainerFull: {
            width: '100vw',

            '& > .scrollbar-container': {
                padding: '20px 20px 0',
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.up('md')]: {
                    padding: '30px 32px 0',
                },
            },
        },
        boxedLayout: {
            [theme.breakpoints.up('lg')]: {
                maxWidth: 1260,
                marginLeft: 'auto',
                marginRight: 'auto',
                boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
                '& .app-sidebar': {
                    position: 'absolute',
                },
                '& $mainContent': {
                    width: 'calc(100% - 19rem)',
                    flex: 'auto',
                },
                '& $mainContainer': {
                    width: '100%',
                },
                '& .app-bar': {
                    width: '100%',
                    position: 'absolute',
                    boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
                },
                '& .grid-btn': {
                    fontSize: 11,
                },
                '& .app-header-fixed': {
                    position: 'absolute',
                },
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: 1680,
                '& $mainContent': {
                    width: 'calc(100% - 21.6rem)',
                },
            },
        },
    };
});
export default AutLayoutStyles;

export const CommonStyles = makeStyles((theme) => ({
    '@global': {
        // for global styles
        '.MuiLink-root': {
            fontWeight: Fonts.REGULAR,
        },
        '.pointer': {
            cursor: 'pointer',
        },
        '.MuiTableCell-stickyHeader': {
            backgroundColor: theme.palette.background.paper,
        },
        '.item-hover': {
            transition: 'all .2s ease',
            transform: 'scale(1)',
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                transform: 'translateY(-2px)',
                boxShadow: `0 3px 10px 0 ${alpha(
                    theme.palette.common.black,
                    0.2,
                )}`,
            },
        },
        '.card-hover': {
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0,0,0,.45)',
                transform: 'scale(1.05)',
            },
        },
    },
}));
