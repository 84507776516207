import {
    SET_INITIAL_PATH,
    TOGGLE_NAV_COLLAPSED,
    SET_CHANNEL_PERCENTAGE,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
    navCollapsed: false,
    initialPath: '/',
    percentage_to_notify: null,
};

const settingsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false,
            };

        case TOGGLE_NAV_COLLAPSED:
            return {
                ...state,
                navCollapsed: !state.navCollapsed,
            };

        case SET_INITIAL_PATH:
            return {
                ...state,
                initialPath: action.payload,
            };
        case SET_CHANNEL_PERCENTAGE:
            return {
                ...state,
                percentage_to_notify: action.payload,
            };

        default:
            return state;
    }
};

export default settingsReducer;
