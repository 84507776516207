import {
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_PASSWORD,
    UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_USERNAME,
    UPDATE_ADDITIONAL_DATA_SOURCE_CLEAR,
    UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_DATA,
    UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_ERROR,
    UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_LOADING,
    UPDATE_ADDITIONAL_DATA_SOURCE_FIELD_VALUE,
    UPDATE_ADDITIONAL_DATA_SOURCE_FIRST_JOIN,
    UPDATE_ADDITIONAL_DATA_SOURCE_FOURTH_JOIN,
    UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS,
    UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_ERROR,
    UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_LOADING,
    UPDATE_ADDITIONAL_DATA_SOURCE_IS_PASSWORD_PROTECTED,
    UPDATE_ADDITIONAL_DATA_SOURCE_MAPPING,
    UPDATE_ADDITIONAL_DATA_SOURCE_NAME,
    UPDATE_ADDITIONAL_DATA_SOURCE_SECOND_JOIN,
    UPDATE_ADDITIONAL_DATA_SOURCE_THIRD_JOIN,
    UPDATE_ADDITIONAL_DATA_SOURCE_TYPE,
    UPDATE_ADDITIONAL_DATA_SOURCE_URL,
    UPDATE_ADDITIONAL_DATA_SOURCE_VIEW_MODE,
    UPDATE_ADDITIONAL_DATA_STEP_INFO,
} from '../../shared/constants/ActionTypes';
import {AdditionalDataSourceType} from '../../shared/constants/AppEnums';

const INIT_STATE = {
    sourceType: AdditionalDataSourceType.GOOGLE_ANALYTICS,
    stepInfo: 0,
    name: '',
    URL: '',
    fieldValue: '',
    isPasswordProtected: false,
    authUsername: '',
    authPassword: '',
    firstJoin: {importField: '', operator: '=', mainDataFields: ''},
    secondJoin: {importField: '', operator: '=', mainDataFields: ''},
    thirdJoin: {importField: '', operator: '=', mainDataFields: ''},
    fourthJoin: {importField: '', operator: '=', mainDataFields: ''},
    importMapping: {},
    importFields: [],
    importFieldsLoading: true,
    importFieldsError: null,
    editImportData: null,
    editImportDataLoading: false,
    editImportDataError: false,
    viewMode: 0, // 0 For Your Additional Sources Page and 1 For add new Source Page
};

const additionalDataSourceReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case '@@router/LOCATION_CHANGE': {
            sessionStorage.clear();
            return INIT_STATE;
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_CLEAR: {
            sessionStorage.clear();
            return INIT_STATE;
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_TYPE: {
            return {
                ...INIT_STATE,
                viewMode: 1,
                sourceType: action.payload,
                stepInfo: 0,
            };
        }
        case UPDATE_ADDITIONAL_DATA_STEP_INFO: {
            return {...state, stepInfo: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_NAME: {
            return {...state, name: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_URL: {
            return {...state, URL: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_FIELD_VALUE: {
            return {...state, fieldValue: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_IS_PASSWORD_PROTECTED: {
            return {...state, isPasswordProtected: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_USERNAME: {
            return {...state, authUsername: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_AUTH_PASSWORD: {
            return {...state, authPassword: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_FIRST_JOIN: {
            return {...state, firstJoin: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_SECOND_JOIN: {
            return {...state, secondJoin: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_THIRD_JOIN: {
            return {...state, thirdJoin: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_FOURTH_JOIN: {
            return {...state, fourthJoin: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_MAPPING: {
            return {...state, importMapping: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS: {
            return {...state, importFields: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_LOADING: {
            return {...state, importFieldsLoading: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_IMPORT_FIELDS_ERROR: {
            return {...state, importFieldsError: action.payload};
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_DATA: {
            return {
                ...state,
                editImportData: action.payload,
            };
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_LOADING: {
            return {
                ...state,
                editImportDataLoading: action.payload,
            };
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_EDIT_IMPORT_ERROR: {
            return {
                ...state,
                editImportDataError: action.payload,
            };
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_VIEW_MODE: {
            return {...INIT_STATE, viewMode: action.payload};
        }
        default:
            return state;
    }
};
export default additionalDataSourceReducer;
