import {toast} from 'react-toastify';

export const successToastConfig = {
    autoClose: 3000,
};

export const errorToastConfig = {
    autoClose: 4000,
};

export const warningToastConfig = {
    autoClose: 4000,
};

export const infoToastConfig = {
    autoClose: 4000,
};

export const infoGetFieldsSuccess = () => {
    toast.info('Please map your fields with CRwizard fields.', {
        ...warningToastConfig,
    });
};

export const couldNotGetFields = () => {
    toast.error(
        'Oops! There is an error in data source fields. Please try again later.',
        {
            ...errorToastConfig,
        },
    );
};
