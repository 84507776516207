import React from 'react';

import {
    EDIT_PRIMARY_FEED_MAPPING_PAGE,
    EDIT_PRIMARY_FEED_PREVIEW_PAGE,
    EDIT_PRIMARY_FEED_RULES_PAGE,
} from '../../../shared/constants/AppRoutes';

export const editPrimaryFeedConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_PRIMARY_FEED_MAPPING_PAGE,
                component: React.lazy(() => import('./MappingPrimaryFeed')),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_PRIMARY_FEED_RULES_PAGE,
                component: React.lazy(() => import('./RulesPrimaryFeed')),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_PRIMARY_FEED_PREVIEW_PAGE,
                component: React.lazy(() => import('./PreviewPrimaryFeed')),
            },
        ],
    },
];
