import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {onJWTAuthSignOut} from '../../../redux/actions';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import clsx from 'clsx';
import {ListItemIcon} from '@material-ui/core';
import {HeaderUserStyles} from '../styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import {authRole, ThemeMode} from '../../constants/AppEnums';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import {IMPERSONATE_PAGE, SETTINGS_PATH} from '../../constants/AppRoutes';
import {useHistory} from 'react-router-dom';
import {Gravatar} from '../Gravatar';

const HeaderUser = (props) => {
    const {themeMode} =
        useSelector((state) => state.appTheme, shallowEqual) || {};
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useAuthUser();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = HeaderUserStyles({themeMode, header: props.header});

    return (
        <Box
            px={{xs: 2, xl: 6}}
            className={clsx(classes.crHeaderUser, 'cr-user-info')}>
            <Box display='flex' alignItems='center'>
                <Box className={clsx(classes.userInfo, 'user-info')}>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'>
                        <Box
                            textAlign='right'
                            className={clsx(classes.userName)}>
                            {user?.first_name ? user.first_name : user?.email}
                            <Box textAlign='right'>
                                {user?.last_name ? user.last_name : null}
                            </Box>
                        </Box>
                        <Box
                            ml={{md: 3}}
                            className={classes.pointer}
                            color={
                                themeMode === ThemeMode.DARK || !props.header
                                    ? 'white'
                                    : '#313541'
                            }>
                            <Menu
                                id='userAvatarMenu'
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                PaperProps={{
                                    style: {
                                        marginTop: '8px',
                                    },
                                }}
                                MenuListProps={{
                                    style: {
                                        paddingTop: '12px',
                                        paddingBottom: '12px',
                                    },
                                }}>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        history.push(SETTINGS_PATH);
                                    }}>
                                    <ListItemIcon
                                        className={classes.menuItemIcon}>
                                        <AccountCircleOutlinedIcon />
                                    </ListItemIcon>
                                    <Typography
                                        variant='body1'
                                        className={classes.menuItemLabel}>
                                        My account
                                    </Typography>
                                </MenuItem>
                                {authRole.admin.includes(user.role) ? (
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => {
                                            history.push(IMPERSONATE_PAGE);
                                        }}>
                                        <ListItemIcon
                                            className={classes.menuItemIcon}>
                                            <AccountCircleOutlinedIcon />
                                        </ListItemIcon>
                                        <Typography
                                            variant='body1'
                                            className={classes.menuItemLabel}>
                                            Impersonate Page
                                        </Typography>
                                    </MenuItem>
                                ) : null}
                                {/*<MenuItem className={classes.menuItem}>*/}
                                {/*    <ListItemIcon*/}
                                {/*        className={classes.menuItemIcon}>*/}
                                {/*        <SettingsIcon />*/}
                                {/*    </ListItemIcon>*/}
                                {/*    <Typography*/}
                                {/*        variant='body1'*/}
                                {/*        className={classes.menuItemLabel}>*/}
                                {/*        Edit Profile*/}
                                {/*    </Typography>*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem className={classes.menuItem}>*/}
                                {/*    <ListItemIcon*/}
                                {/*        className={classes.menuItemIcon}>*/}
                                {/*        <GroupIcon />*/}
                                {/*    </ListItemIcon>*/}
                                {/*    <Typography*/}
                                {/*        variant='body1'*/}
                                {/*        className={classes.menuItemLabel}>*/}
                                {/*        Teams*/}
                                {/*    </Typography>*/}
                                {/*</MenuItem>*/}
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={() => {
                                        dispatch(onJWTAuthSignOut());
                                    }}>
                                    <ListItemIcon
                                        className={classes.menuItemIcon}>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <Typography
                                        variant='body1'
                                        className={classes.menuItemLabel}>
                                        Logout
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
                <Box onClick={handleClick}>
                    <Gravatar user={user} />
                </Box>
            </Box>
        </Box>
    );
};
export default HeaderUser;
HeaderUser.defaultProps = {
    header: true,
};
