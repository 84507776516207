import {
    ADD_RULE,
    CHANGE_TAB,
    DELETE_RULE,
    GET_FIELDS,
    GET_ID_AND_TYPE,
    RECEIVE_RULES,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_RULE,
    UPDATE_ALL_RULES,
    INCLUDE_OR_EXCLUDE,
    RULE_PREVIEW_CHECK_BOX,
    SET_SELECTED_INDEX,
} from '../../shared/constants/ActionTypes';

// Get unique ID for each rule
const getRandomInt = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};

const INIT_STATE = {
    data: [],
    fields: [],
    loading: false,
    tabs: {
        selected: 0,
        isAddMode: false,
    },

    catalogID: '',
    type: '',
    isRetrieved: false,
    ruleCheckBoxes: {},
    selectedIndex: 0,
};

const rulesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return INIT_STATE;
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case ADD_RULE:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: getRandomInt(),
                        dataSourceType: state.type,
                        name: action.payload.name,
                        type: action.payload.type,
                        schema: action.payload.schema,
                        isEdited: false,
                        runBeforeImports: action.payload.runBeforeImports,
                        isActive: false,
                        isSaved: false,
                        filterMode: action.payload?.filterMode,
                    },
                ],
            };

        case UPDATE_RULE:
            return {
                ...state,
                data: state.data.map((rule) =>
                    rule.id === action.payload.id
                        ? {
                              ...rule,
                              ...action.payload,
                              isEdited: true,
                          }
                        : rule,
                ),
            };
        case UPDATE_ALL_RULES:
            return {
                ...state,
                data: action.payload,
            };
        case DELETE_RULE:
            return {
                ...state,
                data: state.data.filter(
                    (rule) => rule.id !== action.payload.id,
                ),
            };
        case RECEIVE_RULES:
            if (action.payload.rules == null) action.payload.rules = [];
            return {
                ...state,
                isRetrieved: true,
                data: action.payload.rules,
                ruleColors: action.payload.rules.map((rule) => rule.color),
            };
        case GET_FIELDS:
            return {
                ...state,
                fields: action.payload.fields,
            };
        case CHANGE_TAB:
            return {
                ...state,
                tabs: {
                    selected: action.payload.newValue,
                    isAddMode: action.payload.isAddMode,
                },
            };

        case GET_ID_AND_TYPE:
            return {
                ...state,
                catalogID: action.payload.catalogID,
                type: action.payload.type,
            };
        case INCLUDE_OR_EXCLUDE:
            return {
                ...state,
                includeOrExclude: action.payload,
            };
        case RULE_PREVIEW_CHECK_BOX:
            return {
                ...state,
                ruleCheckBoxes: {
                    ...state.ruleCheckBoxes,
                    [action.payload]:
                        state.ruleCheckBoxes[action.payload] === true
                            ? false
                            : true,
                },
            };
        case SET_SELECTED_INDEX:
            return {
                ...state,
                selectedIndex: action.payload,
            };

        default:
            return state;
    }
};

export default rulesReducer;
