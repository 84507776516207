import React from 'react';
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';

import AppLocale from '../../../shared/localization';
import PropTypes from 'prop-types';
import {IntlGlobalProvider} from '../Utills';

const LocaleProvider = (props) => {
    const {locale} = useSelector((state) => state.appTheme);
    const currentAppLocale = AppLocale[locale.locale];

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <IntlGlobalProvider>{props.children}</IntlGlobalProvider>
        </IntlProvider>
    );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
