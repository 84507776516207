import React from 'react';

import {
    ADDITIONAL_DATA_SOURCES_PAGE,
    CREATE_PRIMARY_FEED_PAGE,
    DATA_SOURCE_PAGE,
    EDIT_ADDITIONAL_DATA_SOURCE_PAGE,
} from '../../shared/constants/AppRoutes';

const dataSourcePageConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: DATA_SOURCE_PAGE,
                component: React.lazy(() => import('./DataSource')),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: CREATE_PRIMARY_FEED_PAGE,
                component: React.lazy(() =>
                    import('./createPrimaryFeed/index'),
                ),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: ADDITIONAL_DATA_SOURCES_PAGE,
                component: React.lazy(() =>
                    import('./additionalDataSources/addNewDataSource/index'),
                ),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_ADDITIONAL_DATA_SOURCE_PAGE,
                component: React.lazy(() =>
                    import('./additionalDataSources/editDataSource/index'),
                ),
            },
        ],
    },
];

export default dataSourcePageConfig;
