import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import {useAuthToken} from '../AppHooks';
import {Loader} from '../../index';
import PropTypes from 'prop-types';
import {checkPermission} from '../Utills';
import {setInitialPath} from '../../../redux/actions';
import {
    CONFIRM_SIGNUP_PATH,
    ERROR_PAGE_404_PATH,
    FORGET_PASSWORD_PATH,
    INITIAL_URL,
    LOGIN_PATH,
    RESET_PASSWORD_PATH,
    SIGNUP_PATH,
} from '../../../shared/constants/AppRoutes';

const AuthRoutes = ({children}) => {
    const {pathname, search} = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        routes,
        // changeNavStyle,
        // updateThemeStyle,
        // updateThemeMode,
        // setRTL,
    } = useSelector((state) => state.appTheme);

    const [loading, user] = useAuthToken();
    const initialPath = useSelector(({settings}) => settings.initialPath);
    const currentRoute = matchRoutes(routes, pathname)[0].route;
    let isPermitted = checkPermission(
        currentRoute.auth,
        user ? user.role : null,
    );

    useEffect(() => {
        function setInitPath() {
            if (
                initialPath === '/' &&
                [
                    LOGIN_PATH,
                    SIGNUP_PATH,
                    CONFIRM_SIGNUP_PATH,
                    RESET_PASSWORD_PATH,
                    ERROR_PAGE_404_PATH,
                    FORGET_PASSWORD_PATH,
                ].indexOf(pathname) === -1
            ) {
                if (isPermitted) {
                    dispatch(setInitialPath(pathname + search));
                } else {
                    dispatch(setInitialPath(undefined));
                }
            }
        }

        setInitPath();
    }, [dispatch, isPermitted, initialPath, pathname, search]);

    useEffect(() => {
        if (!loading) {
            if (!user && !isPermitted) {
                history.push(LOGIN_PATH); // allowed route
            } else if (user && !isPermitted) {
                history.push(ERROR_PAGE_404_PATH); // Not found
            } else if (user && isPermitted) {
                if (
                    pathname === '/' ||
                    pathname === LOGIN_PATH ||
                    pathname === SIGNUP_PATH
                ) {
                    history.push(INITIAL_URL);
                }
            }
        }
    }, [user, loading, initialPath, isPermitted, pathname, history]);

    return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
    children: PropTypes.node.isRequired,
};
