import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {UPDATE_AUTH_USER} from '../../../shared/constants/ActionTypes';
import {fetchStart, fetchSuccess, setJWTTokens} from '../../../redux/actions';
import {authRole, AuthType} from '../../../shared/constants/AppEnums';
import jwtAxios from '../../services/jwt-api';

export const useAuthToken = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const {user} = useSelector(({auth}) => auth);

    useEffect(() => {
        const validateAuth = async () => {
            dispatch(fetchStart());
            const token = localStorage.getItem('CR_WIZARD_TOKEN_AUTH');
            if (!token) {
                dispatch(fetchSuccess());
                return;
            }
            dispatch(setJWTTokens(token, null));
            try {
                const result = await jwtAxios.get('/auth/users/me/');
                dispatch(fetchSuccess());
                const {email, first_name, last_name, is_staff} = result.data;

                const user = {
                    authType: AuthType.JWT_AUTH,
                    username: `${first_name}${last_name}`,
                    display_name: `${first_name} ${last_name}`,
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    role: is_staff ? authRole.admin[0] : authRole.user[0],
                    token: token,
                    // photoURL:
                    //     'https://i.insider.com/5f341b9e5fa9a45644447e89?width=700',
                };

                dispatch({
                    type: UPDATE_AUTH_USER,
                    payload: user,
                });
            } catch (err) {
                dispatch(fetchSuccess());
            }
        };

        const checkAuth = () => {
            Promise.all([validateAuth()]).then(() => {
                setLoading(false);
            });
        };
        checkAuth();
    }, [dispatch]);

    return [loading, user];
};

export const useAuthUser = () => {
    const {user} = useSelector(({auth}) => auth);

    if (user) {
        return {id: 1, ...user};
    }
    return [null];
};
