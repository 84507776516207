import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import './modules/rules/components/styles.css';

import './shared/styles/index.css';
import App from './App';
import '@crema/services/jwt-api';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

Sentry.init({
    dsn: 'https://5a898b16ee31458f8ac1fd9e60db955d@o1079500.ingest.sentry.io/6084272',
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
