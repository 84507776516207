import React from 'react';
import {
    ADD_CATALOG_PATH,
    EDIT_CATALOG_PATH,
} from '../../shared/constants/AppRoutes';

export const catalogRouteConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: ADD_CATALOG_PATH,
                component: React.lazy(() =>
                    import('./createCatalogPage/index'),
                ),
            },
        ],
    },
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: EDIT_CATALOG_PATH,
                component: React.lazy(() =>
                    import('./createCatalogPage/editCatalogIndex'),
                ),
            },
        ],
    },
];
