import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {AppLayoutStyles} from '../styles';

import {ContentView} from '../../index';
import {CommonStyles} from '../styles';
import AuthLayout from './AuthLayout';
import {Slide, toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CremaLayout = () => {
    CommonStyles();

    // react-toastify
    toast.configure();

    const {user} = useSelector(({auth}) => auth);

    const classes = AppLayoutStyles();
    return (
        <>
            {user ? (
                <>
                    <AuthLayout />
                    <ToastContainer
                        transition={Slide}
                        newestOnTop={true}
                        style={{marginTop: 70}}
                    />
                </>
            ) : (
                <Box className={classes.appAuth}>
                    <ContentView />
                    <ToastContainer newestOnTop={true} transition={Slide} />
                </Box>
            )}
        </>
    );
};

export default React.memo(CremaLayout);
