import React from 'react';
import md5 from 'md5';
import {GravatarStyles} from '../styles';
import {getInitials} from './constant';

export const Gravatar = ({user, size = 42}) => {
    const emailMd5 = md5(user.email);
    const url = `https://www.gravatar.com/avatar/${emailMd5}?s=${String(
        Math.max(size, 250),
    )}&d=blank`;
    const initials = getInitials(user.display_name);

    const c = GravatarStyles({emailMd5, size, initials});

    return (
        <div className={c.parent}>
            <div aria-hidden='true' className={c.swatch}>
                {initials}
            </div>
            <img
                className={c.img}
                src={String(url)}
                alt={`${user.display_name}’s avatar`}
            />
        </div>
    );
};
