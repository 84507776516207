import {
    SET_AUTH_TOKEN,
    SET_REFRESH_TOKEN,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_AUTH_LOADING,
    UPDATE_AUTH_USER,
    UPDATE_GOOGLE_AUTH_ANALYTICS,
    UPDATE_GOOGLE_AUTH_DRIVE,
    UPDATE_GOOGLE_AUTH_LOGIN,
    UPDATE_GOOGLE_AUTH_SIGNUP,
    UPDATE_SHOPIFY_AUTH_LOGIN,
    UPDATE_SHOPIFY_AUTH_REGISTER,
    UPDATE_GOOGLE_MERCHANT_IDS,
    UPDATE_FACEBOOK_AUTH_LOGIN,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/jwt-api';
import {authRole, AuthType} from '../../shared/constants/AppEnums';
import {toast} from 'react-toastify';
import {
    errorToastConfig,
    successToastConfig,
    warningToastConfig,
} from '../../shared/constants/ReactToastify';
import {history} from '../store';
import {
    CONFIRM_SIGNUP_PATH,
    SIGNUP_PATH,
} from '../../shared/constants/AppRoutes';

export const updateAuthLoading = (isLoading) => {
    return (dispatch) =>
        dispatch({type: UPDATE_AUTH_LOADING, payload: isLoading});
};

export const onJwtSignIn = (signInData) => {
    return async (dispatch) => {
        dispatch(updateAuthLoading(true));
        jwtAxios
            .post('/auth/login/', signInData)
            .then((result) => {
                dispatch(setJWTTokens(result.data.access, result.data.refresh));
                dispatch(loadJWTUser(result.data.access, false, true));
            })
            .catch((error) => {
                toast.error(
                    'Something went wrong! ' +
                        (error.response?.data?.error ||
                            error.response?.data?.detail ||
                            ''),
                    {...errorToastConfig},
                );
                dispatch(updateAuthLoading(false));
            });
    };
};

export const onJwtUserSignUp = (signUpData) => {
    return async (dispatch) => {
        dispatch(updateAuthLoading(true));
        jwtAxios
            .post('/auth/register', signUpData)
            .then(() => {
                toast.success('You are successfully registered.', {
                    ...successToastConfig,
                    onClose: () => {
                        dispatch(updateAuthLoading(false));
                        history.push(CONFIRM_SIGNUP_PATH);
                    },
                });
            })
            .catch((error) => {
                toast.error(
                    (error.response?.data?.email ||
                        error.response?.data?.password ||
                        '') + '. Please try again. ',
                    {
                        ...errorToastConfig,
                        onClose: () => {
                            dispatch(updateAuthLoading(false));
                            history.push(SIGNUP_PATH);
                        },
                    },
                );
            });
    };
};

export const onJWTAuthSignOut = () => {
    return (dispatch) => {
        dispatch({type: SIGNOUT_AUTH_SUCCESS});
        localStorage.removeItem('CR_WIZARD_TOKEN_AUTH');
        localStorage.removeItem('CR_WIZARD_TOKEN_REFRESH');
    };
};

export const onJwtForgetPassword = (newPassword, token) => {
    // TODO Will be implemented - Waiting for Backend
};

export const onSetNewPassword = (email, code, new_password, history) => {
    // TODO Will be implemented - Waiting for Backend
};

export const loadJWTUser = (
    access_token,
    isImpersonate = false,
    fromLogin = false,
) => {
    return async (dispatch) => {
        jwtAxios
            .get('/auth/users/me/')
            .then((result) => {
                const {email, first_name, last_name, is_staff} = result.data;

                const user = {
                    authType: AuthType.JWT_AUTH,
                    username: `${first_name}${last_name}`,
                    display_name: `${first_name} ${last_name}`,
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    role: is_staff ? authRole.admin[0] : authRole.user[0],
                    token: access_token,
                    // photoURL:
                    //     'https://i.insider.com/5f341b9e5fa9a45644447e89?width=700',
                    isImpersonate: isImpersonate,
                };

                dispatch({
                    type: UPDATE_AUTH_USER,
                    payload: user,
                });
                if (fromLogin) {
                    toast.success('You are successfully logged in.', {
                        ...successToastConfig,
                        onClose: () => {
                            dispatch(updateAuthLoading(false));
                        },
                    });
                }
            })
            .catch((error) => {
                dispatch(
                    toast.warning(
                        'Something went wrong!' + error.response?.data?.error ||
                            error.response?.data?.detail ||
                            '',
                        {...warningToastConfig},
                    ),
                );
                dispatch(updateAuthLoading(false));
            });
    };
};

export const setJWTTokens = (authToken, refreshToken) => {
    return async (dispatch) => {
        dispatch({
            type: SET_AUTH_TOKEN,
            payload: authToken,
        });
        dispatch({
            type: SET_REFRESH_TOKEN,
            payload: refreshToken,
        });
    };
};

export const facebookAuthLoginUpdate = (facebookAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FACEBOOK_AUTH_LOGIN,
            payload: facebookAuthInfo,
        });
    };
};

export const googleAuthLoginUpdate = (googleAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_GOOGLE_AUTH_LOGIN,
            payload: googleAuthInfo,
        });
    };
};

export const getGoogleMerchantIds = (googleAuthInfo) => {
    return async (dispatch) => {
        jwtAxios
            .post('/support/get_google_merchant_ids', {
                credentials: googleAuthInfo,
            })
            .then((result) => {
                dispatch({
                    type: UPDATE_GOOGLE_MERCHANT_IDS,
                    payload: result.data.merchant_ids,
                });
            })
            .catch((err) => {});
    };
};

export const googleAuthSignUpUpdate = (googleAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_GOOGLE_AUTH_SIGNUP,
            payload: googleAuthInfo,
        });
    };
};

export const googleAuthDriveUpdate = (googleAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_GOOGLE_AUTH_DRIVE,
            payload: googleAuthInfo,
        });
    };
};

export const googleAuthAnalyticsUpdate = (googleAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_GOOGLE_AUTH_ANALYTICS,
            payload: googleAuthInfo,
        });
    };
};

export const shopifyAuthLoginUpdate = (shopifyAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SHOPIFY_AUTH_LOGIN,
            payload: shopifyAuthInfo,
        });
    };
};

export const shopifyAuthRegisterUpdate = (shopifyAuthInfo) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SHOPIFY_AUTH_REGISTER,
            payload: shopifyAuthInfo,
        });
    };
};
