import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utills';

import {ERROR_PAGE_404_PATH, INITIAL_URL} from '../shared/constants/AppRoutes';

import {authRouteConfig} from './auth';
import {catalogRouteConfig} from './catalog';
import dataSourcePageConfig from './dataSource';
import {errorPagesConfigs} from './errorPages';
import {projectsAndTeamsPageConfig} from './projectsAndTeams/constant';
import {settingsRouteConfig} from './settingsPage/constant';
import {editPrimaryFeedConfig} from './dataSource/editPrimaryFeed';
import {editChannelConfig} from './dataSource/channel';
import {impersonatePageConfigs} from './impersonate/constant';

const routeConfigs = [
    ...authRouteConfig,
    ...catalogRouteConfig,
    ...dataSourcePageConfig,
    ...editChannelConfig,
    ...editPrimaryFeedConfig,
    ...errorPagesConfigs,
    ...impersonatePageConfigs,
    ...projectsAndTeamsPageConfig,
    ...settingsRouteConfig,
];

const routes = [
    ...createRoutes(routeConfigs),
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={INITIAL_URL} />,
    },
    {
        component: () => <Redirect to={ERROR_PAGE_404_PATH} />,
    },
];

export default routes;
