import React from 'react';
import {SETTINGS_PATH} from '../../shared/constants/AppRoutes';

export const settingsRouteConfig = [
    {
        auth: ['user', 'admin'],
        routes: [
            {
                path: SETTINGS_PATH,
                component: React.lazy(() => import('./settings')),
            },
        ],
    },
];
