import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import {ConfirmProvider} from 'material-ui-confirm';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';

export const store = configureStore();

const App = () => (
    <Provider store={store}>
        <CremaThemeProvider>
            <CremaStyleProvider>
                <LocaleProvider>
                    <ConfirmProvider>
                        <ConnectedRouter history={history}>
                            <AuthRoutes>
                                <CssBaseline />
                                <AppLayout />
                            </AuthRoutes>
                        </ConnectedRouter>
                    </ConfirmProvider>
                </LocaleProvider>
            </CremaStyleProvider>
        </CremaThemeProvider>
    </Provider>
);

export default App;
