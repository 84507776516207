import {TeamView} from '../../shared/constants/AppEnums';
import {
    CHANGE_TEAM_VIEW,
    SET_AUTH_TEAM_LIST,
    SET_AUTH_TEAM_LIST_LOADING,
    SET_PEOPLE_LIST,
    SET_PROJECT_LIST,
    SET_SELECTED_TEAM,
    SET_TEAM_LIST,
    SIGNOUT_AUTH_SUCCESS,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
    projectList: null,
    teamView: TeamView.TEAMS,
    teamList: null,
    authTeamList: [],
    authTeamListLoading: false,
    selectedTeamID: null,
    peopleList: null,
};

const ProjectsAndTeamsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case CHANGE_TEAM_VIEW: {
            return {...state, teamView: action.payload};
        }
        case SET_SELECTED_TEAM: {
            return {...state, peopleList: [], selectedTeamID: action.payload};
        }
        case SET_PROJECT_LIST: {
            return {...state, projectList: action.payload};
        }
        case SET_TEAM_LIST: {
            return {...state, teamList: action.payload};
        }
        case SET_PEOPLE_LIST: {
            return {...state, peopleList: action.payload};
        }
        case SET_AUTH_TEAM_LIST: {
            return {...state, authTeamList: action.payload};
        }
        case SET_AUTH_TEAM_LIST_LOADING: {
            return {...state, authTeamListLoading: action.payload};
        }
        default:
            return state;
    }
};
export default ProjectsAndTeamsReducer;
