// AUTH PATH
export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/signup';
export const FORGET_PASSWORD_PATH = '/forget-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const CONFIRM_SIGNUP_PATH = '/confirm-signup';
export const ACCOUNT_CONFIRMED = '/account-confirmed';

// ERROR PATH
export const ERROR_PAGE_404_PATH = '/error404';
export const ERROR_PAGE_500_PATH = '/error500';

// APP PATH
// export const MAIN_PAGE_PATH = '/main';
// export const DASHBOARD_PATH = '/dashboard';
export const ADD_CATALOG_PATH = '/add_catalog';
export const EDIT_CATALOG_PATH = '/edit_catalog';
export const SETTINGS_PATH = '/settings_page';
export const PROJECTS_AND_TEAMS_PATH = '/projectsAndTeams';
export const DATA_SOURCE_PAGE = '/data_source';
export const CREATE_PRIMARY_FEED_PAGE = '/create_primary_feed';
export const ADDITIONAL_DATA_SOURCES_PAGE = '/additional_data_sources';
export const EDIT_ADDITIONAL_DATA_SOURCE_PAGE = '/edit_additional_data_source';
export const EDIT_PRIMARY_FEED_MAPPING_PAGE = '/editPrimaryFeedMapping';
export const EDIT_PRIMARY_FEED_RULES_PAGE = '/editPrimaryFeedRules';
export const EDIT_PRIMARY_FEED_PREVIEW_PAGE = '/editPrimaryFeedPreview';
export const EDIT_CHANNEL_MAPPING_PAGE = '/editChannelMapping';
export const EDIT_CHANNEL_RULES_PAGE = '/editChannelRules';
export const EDIT_CHANNEL_PREVIEW_PAGE = '/editChannelPreview';
// export const PRIVACY_PATH = '/privacy';
// export const TERMS_USAGE_PATH = '/terms_and_usage';
export const IMPERSONATE_PAGE = '/adminPage';

export const INITIAL_URL = PROJECTS_AND_TEAMS_PATH;
// export const INITIAL_URL = EDIT_CATALOG_PATH + '?catalogID=123';
