import {
    CLEAR_SUPPORT,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_ADDITIONAL_DATA_SOURCE_TYPE,
    UPDATE_ADDITIONAL_DATA_SOURCE_URL,
    UPDATE_ALL_ANALYTICS_DATA,
    UPDATE_ANALYTICS_ACCOUNTS,
    UPDATE_ANALYTICS_ACCOUNTS_LOADING,
    UPDATE_ANALYTICS_PROPERTIES,
    UPDATE_ANALYTICS_PROPERTIES_LOADING,
    UPDATE_ANALYTICS_VIEWS,
    UPDATE_ANALYTICS_VIEWS_LOADING,
    UPDATE_EXCEL_SHEET_LIST,
    UPDATE_GOOGLE_SHEET_LIST,
    UPDATE_SHOPIFY_LIST,
    UPDATE_STEP1_SOURCE_TYPE,
    UPDATE_SUPPORT_ERROR,
    UPDATE_SUPPORT_LOADING,
    UPDATE_SUPPORT_MESSAGE,
    UPDATE_GET_PUBLIC_SHEETS,
    UPDATE_ADDITIONAL_PUBLIC_SHEETS,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
    excelSheetList: [],
    googleSheetList: [],
    analyticsAccountList: [],
    analyticsAccountListLoading: false,
    analyticsPropertyList: [],
    analyticsPropertyListLoading: false,
    analyticsViewList: [],
    analyticsViewListLoading: false,
    editModeAllFieldsLoading: true,
    isLoading: false,
    hasError: false,
    message: null,
    shopifyList: [],
    publicSheets: null,
    additionalPublicSheets: null,
};

const supportReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return INIT_STATE;
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_TYPE: {
            // After fetch data for Excel go to GoogleSheets and get clean page
            return INIT_STATE;
        }
        case UPDATE_STEP1_SOURCE_TYPE: {
            return INIT_STATE;
        }
        case UPDATE_ADDITIONAL_DATA_SOURCE_URL: {
            return INIT_STATE;
        }
        case UPDATE_EXCEL_SHEET_LIST: {
            return {
                ...state,
                excelSheetList: action.payload,
            };
        }
        case UPDATE_GOOGLE_SHEET_LIST: {
            return {
                ...state,
                googleSheetList: action.payload,
            };
        }
        case UPDATE_GET_PUBLIC_SHEETS: {
            return {
                ...state,
                publicSheets: action.payload,
                googleSheetList: null,
            };
        }
        case UPDATE_ADDITIONAL_PUBLIC_SHEETS: {
            return {
                ...state,
                additionalPublicSheets: action.payload,
                googleSheetList: null,
            };
        }
        case UPDATE_SHOPIFY_LIST: {
            return {
                ...state,
                shopifyShopList: action.payload,
            };
        }
        case UPDATE_SUPPORT_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case UPDATE_SUPPORT_ERROR: {
            return {
                ...state,
                hasError: action.payload,
            };
        }
        case UPDATE_SUPPORT_MESSAGE: {
            return {
                ...state,
                message: action.payload,
            };
        }
        case UPDATE_ANALYTICS_ACCOUNTS: {
            return {
                ...state,
                analyticsAccountList: action.payload,
                analyticsAccountListLoading: false,
                analyticsPropertyList: [],
                analyticsPropertyListLoading: false,
                analyticsViewList: [],
                analyticsViewListLoading: false,
            };
        }
        case UPDATE_ANALYTICS_ACCOUNTS_LOADING: {
            return {
                ...state,
                analyticsAccountListLoading: true,
            };
        }

        case UPDATE_ANALYTICS_PROPERTIES: {
            return {
                ...state,
                analyticsPropertyList: action.payload,
                analyticsPropertyListLoading: false,
                analyticsViewList: [],
                analyticsViewListLoading: false,
            };
        }
        case UPDATE_ANALYTICS_PROPERTIES_LOADING: {
            return {
                ...state,
                analyticsPropertyListLoading: true,
            };
        }
        case UPDATE_ANALYTICS_VIEWS: {
            return {
                ...state,
                analyticsViewList: action.payload,
                analyticsViewListLoading: false,
            };
        }
        case UPDATE_ANALYTICS_VIEWS_LOADING: {
            return {
                ...state,
                analyticsViewListLoading: true,
            };
        }
        case UPDATE_ALL_ANALYTICS_DATA: {
            return {
                ...state,
                analyticsAccountList: action.payload.accountList || [],
                analyticsAccountListLoading: false,
                analyticsPropertyList: action.payload.propertyList || [],
                analyticsPropertyListLoading: false,
                analyticsViewList: action.payload.viewList || [],
                analyticsViewListLoading: false,
                editModeAllFieldsLoading: false,
            };
        }
        case CLEAR_SUPPORT: {
            return INIT_STATE;
        }
        default:
            return state;
    }
};

export default supportReducer;
