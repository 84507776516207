import {
    SET_AUTH_TOKEN,
    SET_REFRESH_TOKEN,
    SIGNOUT_AUTH_SUCCESS,
    UPDATE_AUTH_LOADING,
    UPDATE_AUTH_USER,
    UPDATE_GOOGLE_AUTH_ANALYTICS,
    UPDATE_GOOGLE_AUTH_DRIVE,
    UPDATE_GOOGLE_AUTH_LOGIN,
    UPDATE_GOOGLE_AUTH_SIGNUP,
    UPDATE_SHOPIFY_AUTH_REGISTER,
    UPDATE_SHOPIFY_AUTH_LOGIN,
    UPDATE_GOOGLE_MERCHANT_IDS,
    UPDATE_FACEBOOK_AUTH_LOGIN
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
    authLoading: false,
    user: null,
    authToken: null,
    refreshToken: null,
    googleAuthLogin: null,
    googleAuthSignup: null,
    googleAuthDrive: null,
    googleAuthAnalytics: null,
    merchantIDs: null,
    shopifyAuthLogin: null,
    shopifyAuthSignup: null,
    facebookAuthLogin: null,
};

const authReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return {
                ...state,
                googleAuthLogin: null,
                googleAuthSignup: null,
                googleAuthDrive: null,
                googleAuthAnalytics: null,
            };
        }
        case UPDATE_AUTH_LOADING: {
            return {
                ...state,
                authLoading: action.payload,
            };
        }
        case UPDATE_AUTH_USER: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case SIGNOUT_AUTH_SUCCESS: {
            return INIT_STATE;
        }
        case SET_AUTH_TOKEN: {
            return {
                ...state,
                authToken: action.payload,
            };
        }
        case SET_REFRESH_TOKEN: {
            return {
                ...state,
                refreshToken: action.payload,
            };
        }
        case UPDATE_GOOGLE_AUTH_LOGIN: {
            return {
                ...state,
                googleAuthLogin: action.payload,
            };
        }
        case UPDATE_FACEBOOK_AUTH_LOGIN: {
            return {
                ...state,
                facebookAuthLogin: action.payload,
            };
        }
        case UPDATE_GOOGLE_AUTH_SIGNUP: {
            return {
                ...state,
                googleAuthSignup: action.payload,
            };
        }
        case UPDATE_GOOGLE_AUTH_DRIVE: {
            return {
                ...state,
                googleAuthDrive: action.payload,
            };
        }
        case UPDATE_GOOGLE_AUTH_ANALYTICS: {
            return {
                ...state,
                googleAuthAnalytics: action.payload,
            };
        }
        case UPDATE_GOOGLE_MERCHANT_IDS: {
            return {
                ...state,
                merchantIDs: action.payload,
            };
        }
        case UPDATE_SHOPIFY_AUTH_LOGIN: {
            return {
                ...state,
                shopifyAuthLogin: action.payload,
            };
        }
        case UPDATE_SHOPIFY_AUTH_REGISTER: {
            return {
                ...state,
                shopifyAuthRegister: action.payload,
            };
        }
        default:
            return state;
    }
};
export default authReducer;
