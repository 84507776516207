import React from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {ContentView} from '../../../index';
import Box from '@material-ui/core/Box';
import AutLayoutStyles from '../../styles';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import AppLogo from '../../../../shared/components/AppLogo';
import HeaderUser from '../../../../shared/components/HeaderUser';
import ImpersonateWarning from '../../../../shared/components/ImpersonateWarning';

const AuthLayout = () => {
    const {themeStyle} =
        useSelector((state) => state.appTheme, shallowEqual) || {};
    const classes = AutLayoutStyles(themeStyle);

    return (
        <Box className={classes.appMain}>
            <>
                {/*APP HEADER*/}
                <AppBar
                    color='inherit'
                    className={clsx(classes.appBar, 'app-bar')}>
                    <ImpersonateWarning />
                    <Toolbar className={classes.appToolbar}>
                        <AppLogo />
                        <Box className={classes.grow} />
                        <HeaderUser />
                    </Toolbar>
                </AppBar>
            </>

            <Box className={classes.mainContainerFull}>
                <ContentView />
            </Box>
        </Box>
    );
};

export default AuthLayout;
