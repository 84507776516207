import React from 'react';
import {renderRoutes} from 'react-router-config';
import {AppSuspense} from '../../index';
import routes from '../../../modules';
import Scrollbar from '../Scrollbar';
import Box from '@material-ui/core/Box';
import AppErrorBoundary from '../../core/AppErrorBoundary';

const ContentView = () => {
    return (
        <Scrollbar style={{height: '100%'}}>
            <Box
                display='flex'
                flex={1}
                alignItems='center'
                flexDirection='column'
                className='main-content-view'>
                <AppSuspense>
                    <AppErrorBoundary>{renderRoutes(routes)}</AppErrorBoundary>
                </AppSuspense>
            </Box>
        </Scrollbar>
    );
};

export default ContentView;
