import {baseURL} from '../../../@crema/services/jwt-api';

export const authRole = {
    admin: ['admin'],
    user: ['user', 'admin'],
};

export const AuthType = {
    JWT_AUTH: 'jwt_auth',
};

export const TeamView = {
    TEAMS: 'TeamViewTeams',
    PEOPLE: 'TeamViewPeople',
};

export const CatalogSourceType = {
    XML: 'XML',
    EXCEL: 'Excel',
    GOOGLE_SHEETS: 'Google Sheet',
    CSV_TSV: 'CSV/TSV',
    WooCommerce: 'WooCommerce',
    Shopify: 'Shopify',
};

export const DataSourceChannelType = {
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
};

export const DataSourceExportType = {
    CSV: 'CSV',
    TSV: 'TSV',
    XML: 'XML',
};

export const AdditionalDataSourceType = {
    GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
    XML: 'XML',
    EXCEL: 'Excel',
    GOOGLE_SHEETS: 'Google Sheet',
    CSV_TSV: 'CSV/TSV',
};

export const GOOGLE_AUTH_URL = {
    LOGIN: '',
    SIGNUP: '',
    SHEETS: `${baseURL}/google/redirect?service=sheets`,
    ANALYTICS: `${baseURL}/google/redirect?service=analytics`,
};

export const SHOPIFY_AUTH_URL = {
    LOGIN: `${baseURL}/shopify/redirect?shop=`,
    SIGNUP: '',
};

export const ThemeStyle = {
    STANDARD: 'standard',
};

export const ThemeStyleRadius = {
    MODERN: 30,
    STANDARD: 4,
};

export const ThemeMode = {
    LIGHT: 'light',
    SEMI_DARK: 'semi-dark',
    DARK: 'dark',
};

export const LayoutType = {
    FULL_WIDTH: 'full-width',
};

export const NavStyle = {
    // DEFAULT: 'default',
    // MINI: 'mini',
    // MINI_SIDEBAR_TOGGLE: 'mini_sidebar_toggle',
    // STANDARD: 'standard',
    // HEADER_USER: 'user-header',
    HEADER_USER_MINI: 'user-mini-header',
    // DRAWER: 'drawer',
    // BIT_BUCKET: 'bit-bucket',
    // H_DEFAULT: 'h-default',
    // HOR_LIGHT_NAV: 'hor-light-nav',
    // HOR_DARK_LAYOUT: 'hor-dark-layout',
};

export const FooterType = {
    FIXED: 'fixed',
    FLUID: 'fluid',
};

export const HeaderType = {
    DARK: 'dark',
    LIGHT: 'light',
};

export const RouteTransition = {
    NONE: 'none',
    FADE: 'fade',
    SLIDE_LEFT: 'slideLeft',
    SLIDE_RIGHT: 'slideRight',
    SLIDE_UP: 'slideUp',
    SLIDE_DOWN: 'slideDown',
};

export const Fonts = {
    LIGHT: '300',
    REGULAR: '400',
    MEDIUM: '500',
    BOLD: '600',
    EXTRA_BOLD: '800',
};

export const ROLES = {
    OWNER: 'Owner',
    ADMIN: 'Admin',
    USER: 'User',
    VIEWER: 'Viewer',
    ACCOUNTANT: 'Accountant',
};
