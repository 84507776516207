import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';
import {setAuthToken, setRefreshToken} from '../../@crema/services/jwt-api';

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
    const store = createStore(
        reducers(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    let currentState = store.getState();
    store.subscribe(() => {
        // keep track of the previous and current state to compare changesAppLayout/index.j
        let previousState = currentState;
        currentState = store.getState();
        // if the token changes set the value in localStorage and axios headers
        if (previousState.auth.authToken !== currentState.auth.authToken) {
            const token = currentState.auth.authToken;
            setAuthToken(token);
        }
        if (
            previousState.auth.refreshToken !== currentState.auth.refreshToken
        ) {
            const token = currentState.auth.refreshToken;
            setRefreshToken(token);
        }
    });
    return store;
}
export {history};
